// import React from 'react';
// import gsap from 'gsap';

/**
 * View.jsx
 * simple block to show jsx code written in form
 * create @nk24@CMScom@2023/10/3
 * @param {*} props
 * @returns
 */

import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import { gsap } from 'gsap';
import SplitText from './SplitText.min.js';
import './View.css';
// import './App.css';
// import './index.css';
function View() {
  useEffect(() => {
    setTimeout(() => {
      const catchCopy = document.querySelectorAll('.hero_img .catch_copy');
      const chatchCopyAnimation = () => {
        catchCopy.forEach((text) => {
          if (text.anim) {
            text.anim.progress(1).kill();
            text.split.revert();
          }
          text.split = new SplitText(text, {
            type: 'lines,words,chars',
            linesClass: 'split-line',
          });
          text.anim = gsap.from(text.split.chars, {
            duration: 0.25,
            ease: 'circ.out',
            autoAlpha: 0,
            scale: 0.5,
            stagger: 0.08,
          });
        });
      };
      const tl = gsap.timeline({
        onComplete: chatchCopyAnimation,
      });
      tl.set('.hero_img .img', {
        autoAlpha: 0,
        scale: 0.9,
      })
        .set('.hero_img .catch_copy', {
          autoAlpha: 0,
        })
        .to('.hero_img .img', {
          autoAlpha: 1,
          duration: 2,
          ease: 'Power2.easeOut',
          delay: 1,
          scale: 1,
        })
        .set('.hero_img .catch_copy', {
          autoAlpha: 1,
        });
    }, 0);
  }, []);
  return (
    <div className="Static">
      <div className="hero_img">
        <img className="img" src="/images/hero_img.jpg" alt="Accseration" />
        <p className="catch_copy">
          CMSコミュニケーションズは、
          <br />
          共に成長するパートナーとして、
          <br />
          あなたのビジョンを形にします。
        </p>
      </div>
    </div>
  );
}

export default View;
