/**
 * View title block.
 * @module components/manage/Blocks/Title/View
 * Based on v15.15.0
 * Overrided by nk24@CMScom on 2022/09/21
 */

import React, { useEffect, useRef } from 'react';
import { Segment } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import './View.less';

/**
 * View title block class.
 * @class View
 * @extends Component
 */
const View = ({ properties }) => {
  // const videoEl = useRef(null);

  // const attemptPlay = () => {
  //   videoEl &&
  //     videoEl.current &&
  //     videoEl.current.play().catch((error) => {
  //       console.error('Error attempting to play', error);
  //     });
  // };

  // useEffect(() => {
  //   attemptPlay();
  // }, []);

  // return properties['@type'] === 'Plone Site' ? (
  return (
    <Segment vertical basic inverted color="blue" className="block Title">
      <div className="ui container inner">
        <h1 className="documentFirstHeading">{properties.title}</h1>
      </div>
      {/* <video
        src="http://localhost:3000/mov_hts-samp005.mp4/@@download/file"
        playInline
        ref={videoEl}
        width="40%"
      /> */}
    </Segment>
    // ) : (
    //   <h1 className="documentFirstHeading">{properties.title}</h1>
  );
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
View.propTypes = {
  properties: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default View;
