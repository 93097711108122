/**
 * Add your config changes here.
 * @module config
 * @example
 * export default function applyConfig(config) {
 *   config.settings = {
 *     ...config.settings,
 *     port: 4300,
 *     listBlockTypes: {
 *       ...config.settings.listBlockTypes,
 *       'my-list-item',
 *    }
 * }
 */

/** add block begin@nk24@2023/10/3 */
import { StaticBlockEdit, StaticBlockView } from '@package/components';
import codeSVG from '@plone/volto/icons/code.svg';
/** add block end@nk24@2023/10/3 */

// All your imports required for the config here BEFORE this line
import '@plone/volto/config';

export default function applyConfig(config) {
  // Add here your project's configuration here by modifying `config` accordingly

  /** add block begin@nk24@2023/10/3 */
  config.blocks.blocksConfig.static = {
    id: 'static',
    title: 'Static',
    icon: codeSVG,
    group: 'common',
    view: StaticBlockView,
    edit: StaticBlockEdit,
    restricted: false,
    mostUsed: false,
    sidebarTab: 1,
  };
  /** add block end@nk24@2023/10/3 */

  return config;
}
