import React from 'react';
import View from './View';
import './View.css';

/**
 * Edit.jsx
 * simple block to show jsx code written in form
 * create @nk24@CMScom@2023/10/3
 * @param {*} props
 * @returns
 */
const Edit = (props) => {
  return <View {...props} />;
};

export default Edit;
