import React from 'react';
import { FormattedMessage, defineMessages, injectIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { Button } from 'semantic-ui-react';
import { Icon } from '@plone/volto/components';
import helpSVG from '@plone/volto/icons/help.svg';

import './ContactToLink.less';

const ContactToLink = (props) => {
  return (
    <Button
      compact
      color="teal"
      as={Link}
      to="/contact-form"
      size="mini"
      className="ContactToLink"
    >
      <Icon name={helpSVG} size="18px" /> CONTACT
    </Button>
  );
};

export default injectIntl(ContactToLink);
